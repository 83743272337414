@use "global" as *;

.p-faq{
    background-color: #F2FAFF;
    text-align: center;
    padding-top: rem(85);
    padding-right: rem(80);
    padding-left: rem(30);
    padding-bottom: rem(60);
    @include mq(md){
        padding-right: 0;
        padding-left: 0;
        padding-bottom: rem(190);
    }
}
.p-faq__inner{
    @include mq(md){
        max-width: rem(1600);
        width: 84.5%;
        margin: 0 auto;
    }
}
.p-faq__title{
    color: #E8E6E6;
    font-size: rem(70);
    font-family: 'Oswald', sans-serif;
    margin-bottom: rem(25);
    letter-spacing: 0.1em;
    @include mq(md){
        font-size: calc(200/1900*100vw);
    }
    @media screen and (min-width: 1900px){
        font-size: rem(200);
    }
}
.p-faq__box{
    background-color: #fff;
    border-radius: rem(30);
    padding: rem(30);
    color: #00565F;
    text-align: left;
    margin-bottom: rem(30);
}
.p-faq__flex{
    @include mq(md){
        display: flex;
        align-items: flex-start;
    }
}
.p-faq__trybtn{
    transition: .3s;
    @include mq(md){
        max-width: rem(365);
        margin-left: rem(150);
        font-size: calc(27/1900*100vw);
    }
}
.p-faq__trybtn:hover{
    transform: scale(1.1);
    transition: .3s;
}
.p-faq__question{
    font-size: rem(15);
    margin-bottom: rem(30);
    @include mq(md){
        font-size: rem(25);
        font-weight: 900;
    }
}
.p-faq__answer{
    font-size: rem(13);
    line-height: 1.8;
    @include mq(md){
        font-size: rem(20);
        padding-left: rem(53);
    }
}
.p-faq__trybtn{
    display: inline-block;
    background-color: #E9D427;
    color: #333333;
    margin-top: rem(20);
    width: 100%;
    text-align: center;
    padding-top: rem(7);
    padding-bottom: rem(7);
    border-radius: rem(15);
}