@use "global" as *;

.p-function{
    // padding-right: rem(50);
    padding-top: rem(90);
    padding-bottom: rem(150);
    // background-image: url(../images/function_bg_sp.jpg);
    background-image: url(../images/function_bg_sp.gif);
    background-size: 90%;
    background-repeat: no-repeat;
    // margin-top: rem(-150);
    @include mq(md){
        padding-top: calc(150/1900*100vw);
        padding-bottom: rem(300);
        // padding-left: rem(250);
        // padding-right: rem(250);
        // background-image: url(../images/service_bg.jpg);
        background-image: url(../images/function_bg.gif);
        background-size: 95%;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
.p-function__inner{
    padding-right: rem(50);
    @include mq(md){
        padding-right: calc(150/1900*100vw);
        padding-left: calc(150/1900*100vw);
    }
}
.p-function__head{
    @include mq(md){
        width: 50%;
    }
}
.p-function__title{
    text-align: center;
    color: #00565F;
    font-weight: 400;
    @include mq(md){
        font-size: calc(50/1900*100vw);
    }
}
.p-function__subtitle{
    text-align: center;
    color: #128CA3;
    margin-bottom: rem(30);
    @include mq(md){
        font-size: calc(50/1900*100vw);
    }
}
.p-function__s{
    @include mq(md){
        font-size: calc(30/1900*100vw);
    }
}
.p-function__wrap{
    display: flex;
    flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(min(130px, 100%), 1fr));
    // gap: rem(50);
    align-items: center;
    text-align: center;
    justify-items: center;
    justify-content: center;
    padding-left: rem(20);
    padding-right: rem(10);
    @include mq(md){
        // grid-template-columns: repeat(auto-fit, minmax(min(240px, 100%), 1fr));
    }
}
.p-function__item{
    background-color: #fff;
    border-radius: rem(30);
    box-shadow: 5px 5px 1px 0px #c9c9c9;
    text-align: center;
    max-height: rem(150);
    width: calc(140/400*100vw);
    height: 100%;
    margin-bottom: rem(15);
    margin-right: calc(15/400*100vw);
    padding-top: rem(17);
    padding-bottom: rem(25);
    transition: .3s;
    @include mq(md){
        width: calc(230/1900*100vw);
        max-height: rem(240);
        margin-bottom: rem(60);
        margin-right: calc(60/1900*100vw);
        padding-top: rem(30);
        padding-bottom: rem(30);
    }
}
.p-function__item:hover{
    transform: scale(1.1);
    transition: .3s;
}
.p-function img{
    display: inline-block;
    max-width: rem(140);
    width: 50%;
    height: auto;
}
.p-function__flex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.p-function__flex p{
    text-align: center;

    flex-grow: 1;
}
.p-function__text{
    font-size: calc(11/400*100vw);
    color: #00565F;
    @include mq(md){
        font-size: calc(18/1900*100vw);
    }
}
.p-function__ctabtn{
    text-align: center;
    border: 3px solid #E9D427;
    border-radius: rem(15);
    display: block;
    margin: 0 auto;
    max-width: rem(200);
    width: 90%;
    padding-top: rem(5);
    padding-bottom: rem(5);
    margin-top: rem(20);
    transition: .3s;
    @include mq(md){
        font-size: calc(30/1900*100vw);
        max-width: rem(320);
        transform: translateX(calc(400/1900*100vw));
    }
}
.p-function__ctabtn:hover{
    transform: translateX(400px) scale(1.1);
    transition: .3s;
}
.p-function__shrink02 img{
    width: 33%;
}
.p-function__shrink06 img{
    width: 40%;
}
.p-function__shrink08 img{
    width: 37%;
}