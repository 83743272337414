@use "global" as *;

#profile{
    padding-top: rem(80);
    margin-top: rem(-80);
}
.p-profile{
    padding-bottom: rem(170);
    text-align: center;
    border-top: 1px solid #0e1215;
    border-bottom: 1px solid #0E1215;
    position: relative;
    overflow: hidden;
    @include mq(md){
        padding-top: calc(110/1900*100vw);
        padding-bottom: calc(110/1900*100vw);
        background-color: #ffffff;
        overflow: hidden;
        position: relative;
        border-bottom: none;
    }
}
.p-profile::before{
    @include mq(md){
        content: "";
        position: absolute;
        top: rem(-10);
        left: 0;
        width: 100%;
        height: 6px;
        background-color: #ffffff;
    }
}
.p-profile .l-inner{
    @include mq(md){
        padding-left: calc(110/1900*100vw);
        padding-right: calc(110/1900*100vw);
    }
}
.p-profile__title{
    font-size: rem(30);
    font-family: $font-eng;
    font-weight: 400;
    transform: scale(0.8, 1.0);
    margin-top: rem(-10);
    @include mq(md){
        // font-size: rem(50);
        font-size: calc(50/1900*100vw);
        text-align: left;
        transform: translateX(calc(30/1200*100vw));
    }
}
.p-profile .c-title{
    border-bottom: 1px solid #000000;
    margin-bottom: rem(30);
    padding-top: rem(80);
    margin-top: rem(-80);
    @include mq(md){
        border-bottom: none;
    }
}
.p-profile .c-title h2{
    font-size: rem(50);
    font-family: $font-eng;
    font-weight: 400;
    transform: scale(0.7, 1.0);
    @include mq(md){
        font-size: calc(50/1900*100vw);
    }
}

.p-profile__img{
    width: rem(100);
    margin: 0 auto;
    @include mq(md){
        margin-left: 0;
        margin-right: 0;
        width: calc(100/1900*100vw);
    }
}
.p-profile__name{
    font-size: rem(100);
    font-family: $font-eng;
    font-weight: 400;
    transform: scale(0.8, 1.0);
    @include mq(md){
        font-size: calc(100/1900*100vw);
    }
}
.p-profile__profile{
    text-align: left;
    line-height: 2.0;
    font-size: rem(9);
    width: 90%;
    margin: 0 auto;
    margin-bottom: rem(91);
    @include mq(md){
        font-size: rem(20);
        font-size: calc(20/1900*100vw);
        margin-bottom: 0;
    }
}
.p-profile__iconBox{
    position: relative;
    margin-bottom: rem(60);
    @include mq(md){
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-left: calc(50/1900*100vw);
        padding-right: rem(70);
    }
}
.p-profile__iconBoxLeft{
    @include mq(md){
        display: flex;
        align-items: center;
    }
}
.p-profile__photo{
    width: 85%;
    margin: 0 auto;
}

.p-profile__flex{
    @include mq(md){
        display: flex;
        align-items: center;
    }
}
.p-profile__flexLeft{
    @include mq(md){
        width: 55%;
        padding-left: rem(50);
    }
}
.p-profile__flexRight{
    position: relative;
    // perspective: 500px;
    // overflow: hidden;
    @include mq(md){
        perspective: 500px;
        width: 45%;
    }
}
.p-profile__gearPosition{
    position: absolute;
    top: 48%;
    right: -120px;
    width: rem(200);
    @include mq(md){
        width: 50%;
        position: absolute;
        top: 30%;
        right: rem(-150);
    }

}

.p-profile__gears img{
    animation: rotation 4s linear infinite;
    @keyframes rotation{
        0%{ transform:rotate(0);}
        100%{ transform:rotate(360deg); }
    }
}

.p-profile__slide{
    position: relative;
    transform: rotateY(-10deg);
}
.p-profile__slidebg{
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    @include mq(md){
        width: 80%;
    }
}
.p-profile__slideItems{
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 85%;
    @include mq(md){
        top: calc(55/1900*100vw);
        width: 65%;
    }
}
.p-profile__snsWrap{
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    right: 0;
    @include mq(md){
        position: initial;
        transform: translateY(-15px);
    }
}
.p-profile__snsWrap img{
    margin-left: rem(5);
    margin-right: rem(5);
    width: rem(33);
    @include mq(md){
        width: rem(58);
        margin-left: rem(10);
        margin-right: rem(10);
        width: calc(58/1900*100vw);
    }
}
.next-arrow-profile{
    position: absolute;
    width: calc(140/400*100vw);
    top: calc(-150/400*100vw);
    right: calc(-130/400*100vw);
    transition: 1.0s;
    transform: rotate(0deg);
    @include mq(md){
        position: absolute;
        width: rem(250);
        top: 0;
        right: rem(-270);
        transition: 1.0s;
        transform: rotate(0deg);
    }
}
.next-arrow-profile.is-active{
    transition: 1.0s;
    transform: rotate(180deg);
    @include mq(md){
        transition: 1.0s;
        transform: rotate(180deg);
    }
}
.profile-line{
    margin-bottom: 5px;
    width: 100%;
    height: 6px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
}
.profile-line12{
    margin-bottom: 5px;
    width: 100%;
    height: 12px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
}
.profile-lineBottom{
    margin-top: 5px;
    width: 100%;
    height: 6px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
}