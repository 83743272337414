@use "global" as *;

.p-fv{
    text-align: center;
    @include mq(md){
    }
}
.p-fv__img{
    width: 100%;
    @include mq(md){
    }
}
.p-fv__movie{
    max-width: 100%;
    width: 100%;
    @include mq(md){
        max-width: 100%;
    }
}
.p-fv__box{
    width: 100%;
    margin-bottom: rem(46);
    position: relative;
    @include mq(md){
    }
}
.p-fv__box::before{
    content: "";
    display: block;
    padding-top: calc(((720 / 1280) * 100%));
}
.p-fv__box video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // max-width: 1280px;
    height: auto;
    box-sizing: border-box;
}
.p-fv__text{
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    font-size: rem(20);
    text-align: left;
    margin: 0 auto;
    color: #707070;
    line-height: 3em;
    letter-spacing: 0.5em;
    // border-bottom: 1px solid #0E1215;
    margin-bottom: rem(28);
}
.p-fv__textBorder{
    position: relative;
}
.p-fv__textBorder::before{
    content: "";
    position: absolute;
    bottom: 160px;
    left: 45%;
    transform: translateX(-40%);
    width: 50%;
    height: 1px;
    background-color: #000000;
    animation: fv-text 0.4s ease-out;
    animation-delay: 0.8s;
    animation-fill-mode: both;
    @include mq(md){
        background-color: transparent;
    }
}
@keyframes fv-text{
    0%{
        bottom: 160px;
    }
    40%{
        bottom: 100px;
    }
    // 80%{
    //     bottom: 20px;
    // }
    90%{
        bottom: 10px;
    }
    100%{
        bottom: 0px;
    }

}
