@use "global" as *;

.p-service{
    padding-top: rem(43);
    padding-bottom: rem(75);
    border-bottom: 1px solid #0E1215;
    @include mq(md){
        border-bottom: none;
        padding-top: calc(43/1900*100vw);
        padding-bottom: calc(75/1900*100vw);
    }
}
.p-service__title{
    font-size: rem(15);
    line-height: 2.0;
    color: #191817;
    margin-bottom: rem(44);
    font-weight: 300;
    @include mq(md){
        // font-size: rem(25);
        font-size: calc(25/1900*100vw);
        text-align: left;
    }
}
.p-service__img{
    width: 90%;
    margin: 0 auto;
    margin-bottom: rem(20);
    @include mq(md){
        display: block;
        width: calc(350/1900*100vw);
        margin-right: rem(15);
        margin-left: rem(15);
    }
}
.p-service__text{
    font-size: rem(8);
    @include mq(md){
        // font-size: rem(15);
        font-size: calc(15/1900*100vw);
        text-align: left;
        margin-bottom: rem(30);
    }
}
.p-service__view{
    font-size: rem(25);
    text-align: right;
    padding-right: rem(50);
    position: relative;
    @include mq(md){
        font-size: calc(25/1900*100vw);
    }
}
.p-service__view::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 10%;
    width: 45%;
    height: 2px;
    background-color: #707070;
    animation: "service-border" 2s linear;
}
@keyframes service-border{
    0%{
		width: 0%;
	}
	100%{
		width: 45%;
	}
}
.p-service__slide{
    width: 90%;
    margin: 0 auto;
    @include mq(md){
        width: 70%;
    }
}

.p-service__flex{
    @include mq(md){
        display: flex;
        align-items: center;
    }
}
.p-service__flexLeft{
    @include mq(md){
        width: 45%;
        position: relative;
    }
}
.p-service__flexLeft::before{
    @include mq(md){
        content: "";
        position: absolute;
        top: 0%;
        right: calc(40/1900*100vw);
        width: 1px;
        height: 80%;
        background-color: #707070;
        animation: "service-border2" 2s linear;
    }
}
@keyframes service-border2{
    0%{
		height: 0%;
	}
	100%{
		height: 50%;
	}
}
.p-service__flexRight{
    @include mq(md){
        width: 55%;
        padding-left: rem(25);
    }
}
.p-service__thumbnail{
    display: none !important;
    @include mq(md){
        display: block !important;
        width: 80%;
        margin: 0 auto;
    }
}

.typing-demo2 {
    width: 33ch;
    animation: typing 3s steps(30);
    white-space: nowrap;
    overflow: hidden;
    // border-right: 3px solid;
    letter-spacing:2px;
    margin: 0 auto;
    margin-right: calc(90/1900*100vw);
}
@keyframes typing {
    from {
        width: 0
    }
}
@keyframes blink {
    50% {
        border-color: transparent
    }
}



// Slickドット編集
.dots-class button {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    padding: 0;
    border: none;
    background-color: transparent;
}

.dots-class {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.dots-class li {
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: 1px solid #000066;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color .5s ease;
}
.dots-class li.slick-active {
    background-color: #000066;
}
.dots-class li:hover {
    background-color: #000066;
}
.dots-class li:last-child {
    margin-right: 0;
}
.slick-arrow:before{
	content:""!important;
}




.p-service__ctaBtn{
    display: inline-block;
    border: 5px solid #EBD400;
    border-radius: rem(20);
    width: 60%;
    padding-top: rem(5);
    padding-bottom: rem(5);
    position: relative;
    transition: .3s;
    @include mq(md){
        font-size: calc(27/1900*100vw);
    }
}
.p-service__ctaBtn:hover{
    transform: scale(1.1);
    transition: .3s;
}
.p-service__ctaBtn::before{
    content:"詳しくは";
    position: absolute;
    top: rem(-10);
    left: rem(0);
    color: #005861;
    font-size: rem(8);
    @include mq(md){
        top: rem(-20);
        font-size: rem(20);
    }
}

@media screen and (min-width: 700px) and (max-width: 768px){
    .p-service__slideItem img{
        width: 105%;
    }    
}
.p-service__slideItem{
    position: relative;
}
.p-service__slideItem .p-service__movie{
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translateX(-50%);
    
}
.p-service__slideItem .p-service__video{
    position: absolute;
    width: 50%;
    right: 20%;
}

.p-service__movie{
    width: 100%;
    position: relative;
}
.p-service__video{
    position: absolute;
    bottom: 10%;
    right: 10%;
    width: 27%;
}
.p-service__video2{
    position: absolute;
    bottom: 20%;
    right: 10%;
    width: 30%;
}
.p-service__left{
    position: absolute;
    top: 10%;
    left: 5%;
    color: #00565F;
    width: 90%;
    @include mq(md){
        width: 40%;
    }
    // width: 350px;
    // width: calc(350/1900*100vw);
}
.p-service__header{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: calc(20/1900*100vw);
}
.p-service__num{
    color: #128CA3;
    font-weight: 700;
    margin-right: calc(50/1900*100vw);
    font-size: calc(36/400*100vw);
    @include mq(md){
        font-size: calc(80/1900*100vw);
    }
}
.p-service__middle{
    margin-bottom: calc(20/1900*100vw);
}
.p-service__serviceVideo01{
    margin: 0 auto;
    width: 70%;
    @include mq(md){
        width: 90%;
    }
}
.p-service__serviceVideo02{
    margin: 0 auto;
    width: 55%;
    @include mq(md){
        width: 70%;
    }
}
.p-service__serviceVideo03{
    margin: 0 auto;
    width: 40%;
    @include mq(md){
        width: 50%;
    }
}
.p-service__leftTitle{
    text-align: center;
    font-size: calc(14/400*100vw);
    @include mq(md){
        font-size: calc(30/1900*100vw);
    }
}
.p-service__footer{
    font-size: calc(20/1900*100vw);
    text-align: center;
}


.p-service .prev-arrow{
    position: absolute;
    top: rem(-30);
    left: 30%;
    z-index: 999;
    width: rem(30);
}
.p-service .next-arrow{
    position: absolute;
    top: rem(-30);
    right: 30%;
    width: rem(30);
}
.p-service_anime04{
    width: 70%;
    margin: 0 auto;
}
a.anchor{
    display: block;
    transform: translateY(50px);
}
a.anchor2{
    display: block;
    transform: translateY(-50px);
}
a.anchor3{
    display: block;
    transform: translateY(60px);
}

.transformed{
    background-color: red;
    perspective: 300px;
    width: 300px;
    height: 300px;
    transform-style: flat;
    transform: rotateY(50deg);
}