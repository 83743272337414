@use "global" as *;

.p-header {
    // border-bottom: 1px solid #0E1215;
    position: relative;
    background-color: #EFEDE1;
    @include mq(md) {
        // position: static;
        overflow: hidden;
    }
}
.p-header__borderAnime{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // background-color: rgba(244,242,230,0.4);
    background-color: #EFEDE1;
    z-index: 9999999;
    @include mq(md){
        position: static;
        overflow: hidden;
    }
}
.p-header__borderAnime::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    height: 1px;
    background-color: #000000;
    z-index: 999999;
    animation: "border-anime2" 2s linear;
    @include mq(md){
        left: 8%;
    }
}
@keyframes border-anime2{
    0%{
		width: 0vw;
	}
	100%{
		width: 100vw;
	}
}
.p-header__wrap{
    // max-width: rem(360);
    width: 92%;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: rem(20);
    padding-top: rem(30);
    padding-bottom: rem(14.5);
    padding-left:rem(8);
    // border-left: 1px solid #0E1215;
    position: relative;
    @include mq(md){
    }
}
.p-header__logo img{
    // width: calc(280/400*100vw);
    @include mq(md){
        max-width: rem(500);
        width: calc(500/1200*100vw);
    }
}
.p-drawer__logo img{
    max-width: rem(280);
    @include mq(md){
        max-width: rem(500);
        width: calc(500/1200*100vw);
    }

}
.p-drawer__wrap{
    width: 92%;
    margin-left: auto;
    @include mq(md){
        padding-right: rem(45);
    }
}

.p-header__link{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @include mq(md){
        flex-direction: row;
        position: absolute !important;
        bottom: 10%;
        left: calc(-100/1200*100vw);
    }
}
.p-header__link .p-header__snsText,
.p-header__link .p-header__mailText{
    color: #F4F2E6;
}

.p-header__contact, .p-header__sns, .p-header__mail{
    display: flex;
    align-items: center;
    justify-content: space-around;
    @include mq(md){
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
}
.p-header__contact{
    margin-bottom: rem(35);
    // width: 100%;
    @include mq(md){
        margin-bottom: 0;
        width: calc(400/1900*100vw);
    }
    @media screen and (min-width: 1351px) and (max-width: 1850px) {
        width: calc(500/1900*100vw);
    }
    @media screen and (min-width: 1001px) and (max-width: 1350px) {
        width: calc(600/1900*100vw);
    }
    @media screen and (min-width: 768px) and (max-width: 1000px) {
        width: calc(700/1900*100vw);
    }
}
.p-header__sns{
    width: 50%;
    padding-left: rem(10);
    padding-right: rem(10);
    border-right: 1px solid #707070;
    @include mq(md){
        padding-left: rem(20);
        padding-right: rem(20);
        border-right: 1px solid #707070;
    }
}
.p-header__sns img{
    width: rem(20);
}

.p-header__mail{
    width: 50%;
    padding-left: rem(10);
    padding-right: rem(10);
    @include mq(md){
        padding-left: calc(40/1900*100vw);
        // padding-right: rem(40);
    }
}
.p-header__mail img{
    width: rem(40);
}
.p-header__snsText, .p-header__mailText{
    color: #3C6866;
    font-size: rem(15);
    padding-left: rem(15);
    padding-right: rem(15);
    @include mq(md){
        // font-size: calc(15/1900*100vw);
        padding-left: calc(15/1900*100vw);
        padding-right: calc(15/1900*100vw);
    }
}
.p-header__mail img{
    @include mq(md){
        // width: calc(40/1900*100vw);
    }
}

// ヘッダーナビの部分
.header__nav{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    // opacity: 0;
    // display: none;
    transform: translateY(-100vh);
    // background-image: url(../images/hamburger_bg.jpg);
    background-color: #296966;
    background-size: cover;
    transition: ease .4s;
    z-index: 99999;
    @include mq(md){
        // transform: translate(100vw);
        //transform: translate(75vw, -40vh);
        width: 100vw;
        height: 100vh;
        // display: none;
    }
}
.nav__items{
    position: absolute;
    top: 55%;
    left: 43%;
    transform: translate(-50%, -50%);
    padding: 0;
    @include mq(md){
        left: 50%;
        display: flex;
    }
}
.nav__cta{
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    background-color: #fff;
    border: 3px solid #E9D427;
    border-radius: rem(20);
    width: 80%;
    padding-top: rem(3);
    padding-bottom: rem(3);
    text-align: center;
    font-size: rem(21);
    @include mq(md){
        font-size: calc(18/1900*100vw);
    }
}

// ナビのリンク
.nav-items a{
    color: #F4F2E6;
    width: rem(250);
    display: block;
    font-size: rem(40);
    margin-bottom: rem(20);
    font-family: $font-eng;
    font-weight: 400;
    transform: scale(0.7, 1.0);
    @include mq(md){
        // font-size: calc(12/1900*100vw);
        font-size: calc(40/1400*100vw);
        margin-right: rem(10);
        width: rem(270);
    }
}
.nav-items:last-child a{
    margin-bottom: 0;
}
.nav-items__item{
    @include mq(md){
        // max-width: 100%;
        width: calc(100/800*100vw);
    }
}

// ハンバーガーメニュー
.header__hamburger{
    width: rem(45);
    height: 100%;
    z-index: 9999999;
    @include mq(md){
        position: fixed;
        transform: translateY(-20px);
    }
}
.hamburger{
    background-color: transparent;
    border-color: transparent;
    @include mq(md){
        margin-left: calc(60/1920*100vw);
    }
    @media screen and (max-width: 1500px) {
        margin-left: calc(40/1500*100vw);
    }
    @media screen and (max-width: 1100px) {
        margin-left: calc(25/1100*100vw);
    }
}

// ハンバーガーメニューの線
.hamburger span{
    width: 100%;
    height: 4px;
    background-color: #707070;
    border-radius: rem(50);
    position: relative;
    transition: ease .4s;
    display: block;
}
.hamburger span:nth-child(1){
    top: 0;
}
.hamburger span:nth-child(2){
    margin: 8px 0;
}
.hamburger span:nth-child(3){
    top: 0;
}

// ハンバーガーメニュークリック後のスタイル
.header__nav.active {
    opacity: 1;
    // display: block;
    transform: translateX(0);
    transition: .4s;
    padding-top: calc(50/400*100vw);
    @include mq(md){
        // transform: translateX(50vw);
        //transform: translate(75vw, 85px);
        padding-top: rem(50);
        transition: .5s;
        width: 100vw;
        height: 100vh;
        transform: translateY(0);
    }
}
.header__nav.active .p-drawer__logo{
    margin-bottom: rem(20);
}
.hamburger.active span:nth-child(1){
    top: 10px;
    transform: rotate(45deg);
    background-color: #F4F2E6;
}
.hamburger.active span:nth-child(2){
    opacity: 0;
}
.hamburger.active span:nth-child(3){
    top: -13px;
    transform: rotate(-45deg);
    background-color: #F4F2E6;
}

.p-drawer__head{
    @include mq(md){
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}