@use "global" as *;

.p-intro{
    padding-bottom: rem(70);
    border-bottom: 1px solid #0E1215;
    @include mq(md){
        border-bottom: none;
        padding-bottom: calc(70/1900*100vw);
        padding-top: calc(88/1900*100vw);
    }
}
.p-intro__wrap{
}

.p-intro__flex{
    padding-left: rem(25);
    padding-right: rem(25);
    @include mq(md){
        background-color: #ffffff;
        display: flex;
        align-items: center;
        padding-left: rem(50);
        padding-right: rem(50);
        padding-left: calc(50/1900*100vw);
        padding-right: calc(50/1900*100vw);
    }
}
.p-intro__flexLeft{
    @include mq(md){
        width: 45%;
    }
}
.p-intro__flexRight{
    @include mq(md){
        width: 55%;
        // padding-top: rem(80);
        padding-top: calc(80/1900*100vw);
        padding-left: rem(25);
        padding-bottom: calc(50/1900*100vw);
        // padding-bottom: rem(50);

    }
}

.p-intro__icon{
    width: rem(60);
    margin: 0 auto;
    @include mq(md){
        // width: rem(100);
        width: calc(100/1900*100vw);
    }
}
.p-intro__img{
    width: 90%;
    margin: 0 auto;
    margin-top: rem(28);
    margin-bottom: rem(28);
    @include mq(md){

    }
}
.p-intro__slide{
    @include mq(md){
        width: 70%;
        margin: 0 auto;
    }
}
.p-intro__title{
    font-size: rem(30);
    font-family: $font-eng;
    font-weight: 300;
    transform: scale(0.8, 1.0);
    @include mq(md){
        // font-size: rem(40);
        font-size: calc(40/1900*100vw);
    }
}
.p-intro__name{
    font-size: rem(50);
    font-family: $font-eng;
    font-weight: 400;
    transform: scale(0.8, 1.0);
    margin-bottom: rem(30);
    @include mq(md){
        // font-size: rem(70);
        font-size: calc(70/1900*100vw);
    }
}
.p-intro__explaWrap{
    @include mq(md){
        // margin-bottom: rem(60);
        margin-bottom: calc(60/1900*100vw);
    }
}
.p-intro__explanation{
    font-size: rem(10);
    @include mq(md){
        text-align: left;
        // font-size: rem(25);
        font-size: calc(25/1900*100vw);
    }
}
.p-intro__large{
    font-size: rem(15);
    font-weight: 700;
    @include mq(md){
        // font-size: rem(30);
        font-size: calc(30/1900*100vw);
    }
}
.p-intro__description{
    font-size: rem(9);
    line-height: 2.0;
    margin-bottom: rem(37);
    @include mq(md){
        // font-size: rem(15);
        font-size: calc(15/1900*100vw);
        text-align: left;
        margin-bottom: calc(37/1900*100vw);
    }
}
.p-intro__english{
    font-size: rem(8);
    line-height: 2.0;
    @include mq(md){
        // font-size: rem(15);
        font-size: calc(15/1900*100vw);
        text-align: left;
    }
}


.wrapper {
    // height: 100px;
    // display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.typing-demo {
    width: 38ch;
    animation: typing 2s steps(30), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid transparent;
    letter-spacing:2px;
    margin: 0 auto;
}
@keyframes typing {
    from {
        width: 0
    }
}
@keyframes blink {
    50% {
        border-color: transparent
    }
}
