@use "global" as *;

.p-step{
    padding-top: rem(80);
    padding-right: rem(50);
    padding-bottom: rem(80);
    text-align: center;
    position: relative;
    @include mq(md){
        padding-top: rem(150);
        padding-right: 0;
        padding-bottom: rem(300);
    }
}
.p-step__toplogo{
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
}
.p-step__bottomlogo{
    position: absolute;
    bottom: rem(20);
    right: rem(50);
    width: 60%;
    @include mq(md){
        right: 0;
    }
}
.p-step__drop1{
    position: absolute;
    bottom: calc(10/1900*100vw);
    left: 20%;
    max-width: rem(60);
    width: calc(60/1900*100vw);
    @include mq(md){
        left: 35%;
    }
}
.p-step__drop2{
    position: absolute;
    bottom: calc(-150/1900*100vw);
    left: 18.7%;
    max-width: rem(100);
    width: calc(100/1900*100vw);
    @include mq(md){
        left: 33.7%;
    }
}
.p-step__logo{
    display: inline;
    @include mq(md){
        width: calc(500/1900*100vw);
    }
}
.typing{
    display: flex;
    align-items: center;
    justify-content: center;
}
.p-step__description{
    width: 18ch;
    font-size: rem(16);
    color: #00565F;
    margin-bottom: rem(20);
    animation: typing 3s steps(18), effect .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    @include mq(md){
        font-size: rem(30);
    }
}
@keyframes typing {
    from {
        width: 0
    }
}
@keyframes effect {
    50% {
        border-color: transparent
    }
}

.p-step__slideItem{
}
.p-step__slideFlex{
    @include mq(md){
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-around;
    }
}
.p-step__slideItem img{
    max-width: rem(270);
    width: 90%;
    margin: 0 auto;
    margin-bottom: rem(80);
    @include mq(md){
        margin: 0;
        margin-left: auto;
        padding-right: rem(150);
        margin-top: rem(20);
    }
}
.p-step__slideText{
    color: #00565F;
    @include mq(md){
        width: 40%;
        z-index: 20;
        margin-right: calc(-250/1900*100vw);
        // position: absolute;
        // top: 50%;
        // left: 0;
        // transform: translateY(-50%);
    }
}
.p-step__slideTitle{
    font-size: rem(16);
    margin-bottom: rem(20);
    font-weight: 700;
    @include mq(md){
        font-size: calc(30/1900*100vw);
    }
}
.p-step__slideBody{
    font-size: rem(13);
    margin-bottom: rem(20);
    @include mq(md){
        font-size: calc(20/1900*100vw);
    }
}
.slideBody01{
    @include mq(md){
        margin-bottom: 0;
    }
}

.p-step__ctaBtn{
    display: inline-block;
    background-color: #E9D427;
    border-radius: rem(15);
    max-width: rem(200);
    width: 90%;
    padding-top: rem(5);
    padding-bottom: rem(5);
    transition: .3s;
    @include mq(md){
        margin: 0 auto;
        font-size: calc(18/1900*100vw);
        margin-top: rem(35);
    }
}
.p-step__ctaBtn:hover{
    transform: scale(1.1);
    transition: .3s;
}
.p-step__lineheight{
    line-height: 2.3;
}
.p-step__movie{
    width: 85%;
    margin: 0 auto;
    margin-bottom: rem(50);
    @include mq(md){
        width: 50%;
        margin: 0;
        margin-left: calc(-250/1900*100vw);
        z-index: 10;
        // position: absolute;
        // top: 50%;
        // right: 0;
        // transform: translateY(-50%);
    }
}
.p-step__movie video{
    width: 85%;
    @include mq(md){
        width: 100%;
    }
}
.p-step__mtadj{
    margin-top: rem(-10);
}
.p-step__mtadj3{
    margin-top: rem(40);
}
.p-step__wizard{
    display: none !important;
    margin-left: auto;
    @include mq(md){
        display: block !important;
        max-width: calc(1220/1900*100vw) !important;
        // width: 100% !important;
        text-align: right;
        margin-right: calc(100/1900*50vw);
        z-index: 99999;
        // transform: translateY(-32px);
    }
}

.p-step .prev-arrow{
    position: absolute;
    top: 42%;
    left: rem(30);
    z-index: 999;
    width: calc(30/400*100vw);
    @include mq(md){
        top: 74%;
        left: 12%;
        left: calc(280/1900*100vw);
        width: calc(60/1900*100vw);
    }
    @media screen and (min-width: 768px) and (max-width: 1100px){
        top: 76%;
    }
    @media screen and (min-width: 1600px){
        top: 72%;
    }
}
.p-step .next-arrow{
    position: absolute;
    top: 42%;
    right: rem(30);
    width: calc(30/400*100vw);
    @include mq(md){
        top: 74%;
        left: 38%;
        left: calc(750/1900*100vw);
        width: calc(60/1900*100vw);
    }
    @media screen and (min-width: 768px) and (max-width: 1100px){
        top: 76%;
    }
    @media screen and (min-width: 1600px){
        top: 72%;
    }
}