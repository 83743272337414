@use "global" as *;

.p-media{
    padding-top: rem(30);
    padding-bottom: rem(70);
    text-align: center;
    @include mq(md){
        border-bottom: none;
        padding-top: calc(30/1900*100vw);
        padding-bottom: calc(70/1900*100vw);
    }
}
.p-media .l-inner{
    @include mq(md){
        padding-left: calc(110/1900*100vw);
        padding-right: calc(110/1900*100vw);
    }
}
.p-media__flex{
    @include mq(md){
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}
.p-media .c-title{
    font-size: rem(50);
    margin-bottom: rem(20);
    padding-top: rem(80);
    margin-top: rem(-80);
    font-family: $font-eng;
    font-weight: 300;
    transform: scale(0.8, 1.0);
    @include mq(md){
        // font-size: rem(70);
        font-size: calc(70/1900*100vw);
        width: 0%;
    }
}
.p-media__box{
    @include mq(md){
        // font-size: rem(25);
        font-size: calc(25/1900*100vw);
        padding-top: calc(43/1900*100vw);
        padding-bottom: calc(43/1900*100vw);
        // padding-bottom: rem(43);
        border-top: 1px solid #000000;
        border-bottom: 1px solid #000000;
    }
}
.p-media__flexLeft{
    @include mq(md){
        width: 55%;
        text-align: left;
        padding-left: rem(80);
        padding-right: rem(80);
        transform: translateY(-40px);
    }
}
.p-media__flexRight{
    @include mq(md){
        width: 45%;
    }
}
.p-media__flexRight img{
    @include mq(md){
        width: 70%;
        margin: 0 auto;
    }
}

.p-work__slideItems2{
    @include mq(md){
        position: relative;
    }
}
.prev-arrow-media{
    display: none !important;
    @include mq(md){
        display: block !important;
        position: absolute;
        bottom: rem(-90);
        right: 15%;
        width: calc(55/1900*100vw) !important;
    }
}
.next-arrow-media{
    display: none !important;
    @include mq(md){
        display: block !important;
        position: absolute;
        bottom: rem(-90);
        right: 0%;
        width: calc(55/1900*100vw) !important;
    }
}
.p-media__flexRight .next-arrow-media,
.p-media__flexRight .prev-arrow-media{
    display: none !important;
}