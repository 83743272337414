@use "global" as *;

.p-plan{
    padding-left: rem(30);
    padding-right: rem(80);
    padding-bottom: rem(30);
    background-color: #EFEFEF;
    @include mq(md){
        padding-right: calc(260/1900*100vw);
        padding-left: calc(260/1900*100vw);
        padding-top: calc(150/1900*100vw);
    }
}
.p-plan__planFlex{
    @include mq(md){
        display: flex;
        align-items: center;
    }
}
.p-plan__titleBox{
    position: relative;
    width: 80%;
    margin: 0 auto;
    margin-bottom: rem(20);
    transform: translateY(-30px);
}
.p-plan__titleBox img{
    @include mq(md){
        position: absolute;
        top: calc(-250/1900*100vw);
        right: calc(-170/1900*100vw);
        max-width: rem(585);
        width: calc(585/1900*100vw);
    }
}
.p-plan__mainTitle{
    @include mq(md){
        position: absolute;
        top: calc(-270/1900*100vw);
    }
}
.p-plan__title{
    position: absolute;
    top: calc(10/390*100vw);
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-weight: 400;
    font-size: calc(22/390*100vw);
    @include mq(md){
        top: calc(-230/1900*100vw);
        transform: initial;
        left: initial;
        right: calc(50/1900*100vw);
        font-size: calc(50/1900*100vw);
    }
}
.p-plan__text{
    background-color: #fff;
    color: #00565F;
    display: inline;
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    text-align: center;
    font-size: calc(22/390*100vw);
    @include mq(md){
        font-size: calc(30/1900*100vw);
        width: 40%;
        right: -10%;
        left: initial;
        transform: initial;
        transform: translateY(calc(-70/1900*100vw));
    }
}
.p-plan__planBox{
    background-color: #fff;
    border-top: 12px solid #EAD527;
    color: #00565F;
    text-align: center;
    font-weight: 400;
    padding-top: rem(25);
    padding-bottom: rem(35);
    margin-bottom: rem(30);
    transition: .3s;
    @include mq(md){
        width: 33%;
        margin-right: rem(50);
    }
}
.p-plan__planBox:hover{
    transform:scale(1.1);
    transition: .3s;
}
.p-plan__planBox:last-child{
    margin-right: 0;
}
.p-plan__planBox:nth-child(1){
    border-top: 12px solid #EAD527;
}
.p-plan__planBox:nth-child(2){
    border-top: 12px solid #EDAB44;
}
.p-plan__planBox:nth-child(3){
    border-top: 12px solid #128DA4;
}
.p-plan__boxTitle{
    font-size: rem(20);
    font-weight: 900;
    margin-bottom: rem(5);
    @include mq(md){
        font-size: calc(30/1900*100vw);
    }
}
.p-plan__subtitle{
    font-size: rem(13);
    margin-bottom: rem(20);
    font-weight: 900;
    @include mq(md){
        font-size: calc(20/1900*100vw);
    }
}
.p-plan__price{
    font-size: rem(16);
    margin-bottom: rem(5);
    font-weight: 900;
    @include mq(md){
        font-size: calc(25/1900*100vw);
    }
}
.p-plan__planBox img{
    display: inline;
}
.p-plan__img1{
    width: 39%;
}
.p-plan__img2{
    width: 52%;
}
.p-plan__img3{
    width: 40%;
}
.p-plan__services{
    max-width: 150px;
    width: 100%;
    margin: 0 auto;
    text-align: left;
    padding-left: rem(20);
    @include mq(md){
        max-width: rem(200);
    }
}
.p-plan__service{
    @include mq(md){
        font-size: calc(20/1900*100vw);
    }
}
.service_mark{
    position: relative;
}
.p-plan__service::before{
    position: absolute;
    content: url(../images/plan_item_logo.svg);
    top: rem(3);
    left: rem(-25);
    width: rem(15)
}
.p-plan__planBox:nth-child(2) .service_mark::before{
    content: url(../images/plan_item_logo_orange.svg);
}
.p-plan__planBox:nth-child(3) .service_mark::before{
    content: url(../images/plan_item_logo_buz.svg);
}
.p-plan__text{
    font-size: calc(20/390*100vw);
    font-weight: 400;
    @include mq(md){
        font-size: calc(50/1900*100vw);
    }
}
@media screen and (max-width: 390px) {
    .p-plan__text{
        font-size: calc(15/390*100vw);
    }
    
}
.p-plan__text--s{
    font-size: calc(13/390*100vw);
    @include mq(md){
        font-size: calc(30/1900*100vw);
    }
}
.plus::before{
    content: "";
}


.plus{
    @include mq(md){
        padding-left: rem(50);
    }
}
.p-plan__mb{
    margin-bottom: calc(20/1900*100vw);
}