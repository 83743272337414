@use "global" as *;

#contact{
    padding-top: rem(80);
    margin-top: rem(-80);
}
.p-contact{
    padding-bottom: rem(70);
    text-align: center;
    // border-bottom: 1px solid #0E1215;
    @include mq(md){
        padding-top: rem(130);
        padding-top: calc(130/1900*100vw);
        padding-left: calc(110/1900*100vw);
        padding-right: calc(110/1900*100vw);
    }
}
.p-contact__title{
    border-bottom: 1px solid #000000;
    margin-top: rem(-10);
    margin-bottom: rem(-15);
    // padding-top: rem(80);
    // margin-top: rem(-90);
    @include mq(md){
        text-align: left;
        border-top: none;
        border-bottom: none;
        width: 40%;
        margin-top: 0;
        margin-bottom: 0;
    }
}
.p-contact__title h2{
    font-family: $font-eng;
    font-weight: 300;
    transform: scale(0.7, 1.0);
    font-size: rem(50);
    @include mq(md){
        font-size: rem(70);
        font-size: calc(70/1900*100vw);
        transform: translateX(calc(-35/1900*100vw)) scale(0.7, 1.0);
        text-align: left;
    }
}
.p-contact__subtitle{
    text-align: left;
    margin-left: rem(60);
    @include mq(md){
        font-size: calc(16/1900*100vw);
    }
}
.p-contact__form{
    padding-top: rem(36);
    @include mq(md){
        width: 55%;
    }
}
.p-contact__form div{
    width: 85%;
    margin: 0 auto;
    margin-bottom: rem(15);
    padding-left: rem(10);
    @include mq(md){
    }
}
.p-contact__form label{
    font-size: rem(10);
    color: #191817;
    display: block;
    text-align: left;
    margin-bottom: rem(5);
}
.p-contact__form input{
    width: 100%;
    border: 1px solid #707070;
    border-radius: rem(30);
    height: rem(50);
    @include mq(md){
        height: rem(30);
    }
}
.p-contact__form select{
    width: 100%;
    border: 1px solid #707070;
    border-radius: rem(30);
    height: rem(50);
    font-size: rem(10);
    @include mq(md){
        width: 70%;
        height: rem(30);
    }
}
.p-contact__form textarea{
    width: 100%;
    border: 1px solid #707070;
    height: rem(160);
    border-radius: rem(20);
    @include mq(md){
        width: 100%;
        height: rem(100);
    }
}
.p-form__caution{
    font-size: rem(12);
    @include mq(md){
        text-align: left;
    }
}
.p-form__red{
    color: #FF2700;
}
.p-form__group{
    display: inline-block;
    display: flex;
    align-items: center;
    width: 100% !important;
    @include mq(md){
        width: rem(350) !important;
        margin-right: calc(70/1900*100vw) !important;
    }
}
.p-form__group input{
    width: rem(30);
}
.p-form__text{
    display: inline-block;
    margin-bottom: 0 !important;
    font-size: rem(12);
    text-align: center;
    width: 100% !important;
    @include mq(md){
        text-align: left;
        padding-left: rem(30);
    }
}
.p-contact__label{
    @include mq(md){
        width: 30%;
    }
}
.p-contact__input{
    @include mq(md){
        width: 70%;
        height: rem(30);
    }
}
.p-contact__flex{
    @include mq(md){
        display: flex;
        align-items: center;
    }
}
.p-contact__form p{
    // display: none;
}
.p-contact__flex br{
    display: none;
}
.p-form__caution br:nth-child(2n){
    display: none;
}
.p-form__caution .u-mobile+br{
    @include mq(md){
        display: none;
    }
}
.p-contact__flex span{
    width: 70%;
}

.p-form__checkbox2{
    display: inline-block;
    display: flex;
    align-items: center;
    width: 65% !important;
    @include mq(md){
        width: rem(350) !important;
        margin-right: calc(70/1900*100vw) !important;
    }
}
.p-form__checkbox2 input{
    width: rem(20);
    transform: translateY(-8px);
    @include mq(md){
        width: rem(30);
        transform: translateY(-10px) scale(2);
    }
}

.submit-button {
    transition: all 0.3s;
    opacity: 0.6;
    width: initial !important;
    height: auto !important;
    border: none !important;
    @include mq(md){
        width: rem(100) !important;
        transform: translateY(0) !important;
    }
}
.submit-button.-active {
    opacity: 1;
}
.submit-button.-active:hover {
    // background: #6020B0;
}