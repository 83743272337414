@use "global" as *;

// インナー幅＋余白
$breakpointInner: $innerWidth + strip-unit($padding-pc) * 2;

// ルートフォント設定
html {
  // ~ 375px
  @media (max-width: 375px) {
    font-size: vw(375, 16);
  }

  // 376px ~ 767px
  font-size: 100%;

  // 768px ~ インナー幅＋余白
  @include mq(md) {
    font-size: vw(strip-unit($breakpointInner), 16);
  }

  // インナー幅＋余白 ~
  @media ( min-width: $breakpointInner ) {
    font-size: 100%;
  }
}
html{
  scroll-behavior: smooth;
  // background-color: #F4F2E6;
  background-color: #EFEDE1;
}

// フォント、カラー設定
body {
  font-family: $font-main;
  color: $black;
  padding-top: calc(110/400*100vw);
  @media screen and (min-width: 500px) and (max-width: 767px) {
    padding-top: calc(95/500*100vw);  
  }
  @include mq(md){
    padding-top: 0;
  }
}

// ホバー
a,
button {
  @include mq(md) {
    transition: .3s;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}