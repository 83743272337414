@use "global" as *;

.p-work{
    padding-top: rem(30);
    padding-bottom: rem(250);
    text-align: center;
    border-bottom: 1px solid #0E1215;
    @include mq(md){
        position: relative;
        border-bottom: none;
        padding-top: calc(30/1900*100vw);
        padding-bottom: calc(250/1900*100vw);
    }
}
.p-work__text{
    margin-top: rem(20);
    font-size: rem(12);
    line-height: 1.0;
    margin-bottom: rem(50);
}
.p-work__img{
    width: 60%;
    margin: 0 auto;
    @include mq(md){
        width: 60%;
    }
}
.p-work__box{
    font-size: rem(12);
    line-height: 2.0;
    position: relative;
}
.p-work__flex{
    @include mq(md){
        display: flex;
        align-items: center;
        position: absolute;
        top: rem(-100);
        left: 0;
        width: 100%;
        @include mq(md){
            left: 50%;
            transform: translateX(-50%);
            width: 88%;
        }
    }
}
.p-work__flexLeft{
    @include mq(md){
        width: 50%;
        padding-top: rem(300);
        text-align: left;
        padding-left: rem(80);
        padding-right: rem(80);
    }
}
.p-work__flexRight{
    // position: relative;
    @include mq(md){
        width: 50%;
        position: relative;
    }
}
.p-work__background{
    transform: translateY(50px);
    @include mq(md){
        position: absolute;
        top: 0;
        left: 0;
        // z-index: 99;
    }
}
.p-work__backItems{
    // transform: scaleY(0.5);
    width: 60%;
    margin: 0 auto;
    @include mq(md){
        // transform: scale(1.22, 0.9);
    }
}
.p-work__backItems video{
    width: 100%;
    @include mq(md){
        width: 100%;
    }
}
.p-work__background .slick-next{
    display: none !important;
}
.p-work__slide{
    position: absolute;
    top: -5%;
    left: 0;
    width: 100%;
    z-index: 99;
    @include mq(md){
        top: 50%;
    }
}
.slick-list{
    height: 50%;
}
.p-work__display{
    display: none !important;
    @include mq(md){
        display: block !important;
    }
}
.p-work .c-title{
    font-size: rem(50);
    margin-bottom: rem(25);
    padding-top: rem(80);
    margin-top: rem(-80);
    font-family: $font-eng;
    font-weight: 300;
    transform: scale(0.8, 1.0);
    @include mq(md){
        // font-size: rem(70);
        font-size: calc(70/1900*100vw);
        width: 0%;
    }
}
.p-work__text{
    @include mq(md){
        // font-size: rem(25);
        font-size: calc(25/1900*100vw);
    }
}
.p-work__slideItems{
    @include mq(md){
        position: relative;
    }
}
.prev-arrow{
    display: none !important;
    @include mq(md){
        display: block !important;
        position: absolute;
        bottom: rem(-70);
        left: 40%;
        width: calc(55/1900*100vw);
    }
}
.next-arrow{
    display: none !important;
    @include mq(md){
        display: block !important;
        position: absolute;
        bottom: rem(-70);
        right: 40%;
        width: calc(55/1900*100vw);
    }
}
.p-workForBack .prev-arrow,
.p-workForBack .next-arrow{
    display: none !important;
}