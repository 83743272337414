@use 'sass:math';

// インナー幅
$innerWidth: 1024px;

// 余白
$padding-pc: 25px;
$padding-sp: 25px;

// フォント
$font-main: "Noto Serif JP", serif;
$font-eng: "Oswald", sans-serif;

.font-narrow{
  transform: scale(0.8, 1.0);
}

// 色指定
$black: #333;
$white: #fff;

// ブレイクポイント
$breakpoints: (
  sm: 600,
  md: 768,
  lg: 1024,
  xl: 1440,
);

// メディアクエリ
$mediaquerys: (
  sm: "screen and (min-width: #{map-get($breakpoints,'sm')}px)",
  md: "screen and (min-width: #{map-get($breakpoints,'md')}px)",
  lg: "screen and (min-width: #{map-get($breakpoints,'lg')}px)",
  xl: "screen and (min-width: #{map-get($breakpoints,'xl')}px)",
);

// メディアクエリを "@include mq(){}" で書くことができる
@mixin mq($mediaquery: md) {
  @media #{map-get($mediaquerys, $mediaquery)} {
    @content;
  }
}