@use "global" as *;

.p-footer{
    height: rem(30);
    text-align: center;
    position: relative;
    // border-top: 1px solid #0E1215;
    @include mq(md){
        padding-right: 0;
        height: rem(40);
    }
}
.p-footer::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000000;
    animation: "footer-anime" 5s linear;
}
@keyframes footer-anime
{
    0%{
        width: 0%;
    }
    100%{
        width: 100%;
    }
}

.p-footer__wrap{
    height: rem(30);
    padding-left: 8%;
    @include mq(md){
        height: rem(40);
    }    
}