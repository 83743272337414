@charset "UTF-8";
.font-narrow {
  transform: scale(0.8, 1);
}

html {
  font-size: 100%;
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 1.4897579143vw;
  }
}
@media (min-width: 1074px) {
  html {
    font-size: 100%;
  }
}

html {
  scroll-behavior: smooth;
  background-color: #EFEDE1;
}

body {
  font-family: "Noto Serif JP", serif;
  color: #333;
  padding-top: 27.5vw;
}
@media screen and (min-width: 500px) and (max-width: 767px) {
  body {
    padding-top: 19vw;
  }
}
@media screen and (min-width: 768px) {
  body {
    padding-top: 0;
  }
}

@media screen and (min-width: 768px) {
  a,
  button {
    transition: 0.3s;
  }
  a:hover,
  button:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

/*****************************
* A Modern CSS Reset (https://github.com/hankchizljaw/modern-css-reset)
* 上記に、ul要素,ol要素,a要素への記述追加
*****************************/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd,
ul,
li {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
  padding: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  border: none;
  padding: 0;
  background-color: initial;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.l-inner {
  width: 100%;
  padding-right: 1.5625rem;
  padding-left: 1.5625rem;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .l-inner {
    padding-right: 1.5625rem;
    padding-left: 1.5625rem;
  }
}

.p-bodyWrap {
  width: 100%;
  padding-left: 7.5vw;
  margin-left: auto;
  text-align: center;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-bodyWrap {
    width: 92%;
    padding-left: 0;
  }
}

body {
  position: relative;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 7.5vw;
  width: 1px;
  height: 100%;
  background-color: #000000;
  z-index: 999999;
  animation: "border-anime" 10s linear;
}
@media screen and (min-width: 768px) {
  body::before {
    left: 7.9365079365vw;
  }
}

@keyframes border-anime {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}
#contact {
  padding-top: 5rem;
  margin-top: -5rem;
}

.p-contact {
  padding-bottom: 4.375rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .p-contact {
    padding-top: 8.125rem;
    padding-top: 6.8421052632vw;
    padding-left: 5.7894736842vw;
    padding-right: 5.7894736842vw;
  }
}

.p-contact__title {
  border-bottom: 1px solid #000000;
  margin-top: -0.625rem;
  margin-bottom: -0.9375rem;
}
@media screen and (min-width: 768px) {
  .p-contact__title {
    text-align: left;
    border-top: none;
    border-bottom: none;
    width: 40%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.p-contact__title h2 {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  transform: scale(0.7, 1);
  font-size: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-contact__title h2 {
    font-size: 4.375rem;
    font-size: 3.6842105263vw;
    transform: translateX(-1.8421052632vw) scale(0.7, 1);
    text-align: left;
  }
}

.p-contact__subtitle {
  text-align: left;
  margin-left: 3.75rem;
}
@media screen and (min-width: 768px) {
  .p-contact__subtitle {
    font-size: 0.8421052632vw;
  }
}

.p-contact__form {
  padding-top: 2.25rem;
}
@media screen and (min-width: 768px) {
  .p-contact__form {
    width: 55%;
  }
}

.p-contact__form div {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 0.9375rem;
  padding-left: 0.625rem;
}
.p-contact__form label {
  font-size: 0.625rem;
  color: #191817;
  display: block;
  text-align: left;
  margin-bottom: 0.3125rem;
}

.p-contact__form input {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 1.875rem;
  height: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-contact__form input {
    height: 1.875rem;
  }
}

.p-contact__form select {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 1.875rem;
  height: 3.125rem;
  font-size: 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-contact__form select {
    width: 70%;
    height: 1.875rem;
  }
}

.p-contact__form textarea {
  width: 100%;
  border: 1px solid #707070;
  height: 10rem;
  border-radius: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-contact__form textarea {
    width: 100%;
    height: 6.25rem;
  }
}

.p-form__caution {
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .p-form__caution {
    text-align: left;
  }
}

.p-form__red {
  color: #FF2700;
}

.p-form__group {
  display: inline-block;
  display: flex;
  align-items: center;
  width: 100% !important;
}
@media screen and (min-width: 768px) {
  .p-form__group {
    width: 21.875rem !important;
    margin-right: 3.6842105263vw !important;
  }
}

.p-form__group input {
  width: 1.875rem;
}

.p-form__text {
  display: inline-block;
  margin-bottom: 0 !important;
  font-size: 0.75rem;
  text-align: center;
  width: 100% !important;
}
@media screen and (min-width: 768px) {
  .p-form__text {
    text-align: left;
    padding-left: 1.875rem;
  }
}

@media screen and (min-width: 768px) {
  .p-contact__label {
    width: 30%;
  }
}

@media screen and (min-width: 768px) {
  .p-contact__input {
    width: 70%;
    height: 1.875rem;
  }
}

@media screen and (min-width: 768px) {
  .p-contact__flex {
    display: flex;
    align-items: center;
  }
}

.p-contact__flex br {
  display: none;
}

.p-form__caution br:nth-child(2n) {
  display: none;
}

@media screen and (min-width: 768px) {
  .p-form__caution .u-mobile + br {
    display: none;
  }
}

.p-contact__flex span {
  width: 70%;
}

.p-form__checkbox2 {
  display: inline-block;
  display: flex;
  align-items: center;
  width: 65% !important;
}
@media screen and (min-width: 768px) {
  .p-form__checkbox2 {
    width: 21.875rem !important;
    margin-right: 3.6842105263vw !important;
  }
}

.p-form__checkbox2 input {
  width: 1.25rem;
  transform: translateY(-8px);
}
@media screen and (min-width: 768px) {
  .p-form__checkbox2 input {
    width: 1.875rem;
    transform: translateY(-10px) scale(2);
  }
}

.submit-button {
  transition: all 0.3s;
  opacity: 0.6;
  width: initial !important;
  height: auto !important;
  border: none !important;
}
@media screen and (min-width: 768px) {
  .submit-button {
    width: 6.25rem !important;
    transform: translateY(0) !important;
  }
}

.submit-button.-active {
  opacity: 1;
}

.p-faq {
  background-color: #F2FAFF;
  text-align: center;
  padding-top: 5.3125rem;
  padding-right: 5rem;
  padding-left: 1.875rem;
  padding-bottom: 3.75rem;
}
@media screen and (min-width: 768px) {
  .p-faq {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 11.875rem;
  }
}

@media screen and (min-width: 768px) {
  .p-faq__inner {
    max-width: 100rem;
    width: 84.5%;
    margin: 0 auto;
  }
}

.p-faq__title {
  color: #E8E6E6;
  font-size: 4.375rem;
  font-family: "Oswald", sans-serif;
  margin-bottom: 1.5625rem;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) {
  .p-faq__title {
    font-size: 10.5263157895vw;
  }
}
@media screen and (min-width: 1900px) {
  .p-faq__title {
    font-size: 12.5rem;
  }
}

.p-faq__box {
  background-color: #fff;
  border-radius: 1.875rem;
  padding: 1.875rem;
  color: #00565F;
  text-align: left;
  margin-bottom: 1.875rem;
}

@media screen and (min-width: 768px) {
  .p-faq__flex {
    display: flex;
    align-items: flex-start;
  }
}

.p-faq__trybtn {
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .p-faq__trybtn {
    max-width: 22.8125rem;
    margin-left: 9.375rem;
    font-size: 1.4210526316vw;
  }
}

.p-faq__trybtn:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.p-faq__question {
  font-size: 0.9375rem;
  margin-bottom: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-faq__question {
    font-size: 1.5625rem;
    font-weight: 900;
  }
}

.p-faq__answer {
  font-size: 0.8125rem;
  line-height: 1.8;
}
@media screen and (min-width: 768px) {
  .p-faq__answer {
    font-size: 1.25rem;
    padding-left: 3.3125rem;
  }
}

.p-faq__trybtn {
  display: inline-block;
  background-color: #E9D427;
  color: #333333;
  margin-top: 1.25rem;
  width: 100%;
  text-align: center;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  border-radius: 0.9375rem;
}

.p-footer {
  height: 1.875rem;
  text-align: center;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-footer {
    padding-right: 0;
    height: 2.5rem;
  }
}

.p-footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000000;
  animation: "footer-anime" 5s linear;
}

@keyframes footer-anime {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.p-footer__wrap {
  height: 1.875rem;
  padding-left: 8%;
}
@media screen and (min-width: 768px) {
  .p-footer__wrap {
    height: 2.5rem;
  }
}

.p-function {
  padding-top: 5.625rem;
  padding-bottom: 9.375rem;
  background-image: url(../images/function_bg_sp.gif);
  background-size: 90%;
  background-repeat: no-repeat;
}
@media screen and (min-width: 768px) {
  .p-function {
    padding-top: 7.8947368421vw;
    padding-bottom: 18.75rem;
    background-image: url(../images/function_bg.gif);
    background-size: 95%;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.p-function__inner {
  padding-right: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-function__inner {
    padding-right: 7.8947368421vw;
    padding-left: 7.8947368421vw;
  }
}

@media screen and (min-width: 768px) {
  .p-function__head {
    width: 50%;
  }
}

.p-function__title {
  text-align: center;
  color: #00565F;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .p-function__title {
    font-size: 2.6315789474vw;
  }
}

.p-function__subtitle {
  text-align: center;
  color: #128CA3;
  margin-bottom: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-function__subtitle {
    font-size: 2.6315789474vw;
  }
}

@media screen and (min-width: 768px) {
  .p-function__s {
    font-size: 1.5789473684vw;
  }
}

.p-function__wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-items: center;
  justify-content: center;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}
.p-function__item {
  background-color: #fff;
  border-radius: 1.875rem;
  box-shadow: 5px 5px 1px 0px #c9c9c9;
  text-align: center;
  max-height: 9.375rem;
  width: 35vw;
  height: 100%;
  margin-bottom: 0.9375rem;
  margin-right: 3.75vw;
  padding-top: 1.0625rem;
  padding-bottom: 1.5625rem;
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .p-function__item {
    width: 12.1052631579vw;
    max-height: 15rem;
    margin-bottom: 3.75rem;
    margin-right: 3.1578947368vw;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
}

.p-function__item:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.p-function img {
  display: inline-block;
  max-width: 8.75rem;
  width: 50%;
  height: auto;
}

.p-function__flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.p-function__flex p {
  text-align: center;
  flex-grow: 1;
}

.p-function__text {
  font-size: 2.75vw;
  color: #00565F;
}
@media screen and (min-width: 768px) {
  .p-function__text {
    font-size: 0.9473684211vw;
  }
}

.p-function__ctabtn {
  text-align: center;
  border: 3px solid #E9D427;
  border-radius: 0.9375rem;
  display: block;
  margin: 0 auto;
  max-width: 12.5rem;
  width: 90%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-top: 1.25rem;
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .p-function__ctabtn {
    font-size: 1.5789473684vw;
    max-width: 20rem;
    transform: translateX(21.0526315789vw);
  }
}

.p-function__ctabtn:hover {
  transform: translateX(400px) scale(1.1);
  transition: 0.3s;
}

.p-function__shrink02 img {
  width: 33%;
}

.p-function__shrink06 img {
  width: 40%;
}

.p-function__shrink08 img {
  width: 37%;
}

.p-fv {
  text-align: center;
}
.p-fv__img {
  width: 100%;
}
.p-fv__movie {
  max-width: 100%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .p-fv__movie {
    max-width: 100%;
  }
}

.p-fv__box {
  width: 100%;
  margin-bottom: 2.875rem;
  position: relative;
}
.p-fv__box::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}

.p-fv__box video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.p-fv__text {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  font-size: 1.25rem;
  text-align: left;
  margin: 0 auto;
  color: #707070;
  line-height: 3em;
  letter-spacing: 0.5em;
  margin-bottom: 1.75rem;
}

.p-fv__textBorder {
  position: relative;
}

.p-fv__textBorder::before {
  content: "";
  position: absolute;
  bottom: 160px;
  left: 45%;
  transform: translateX(-40%);
  width: 50%;
  height: 1px;
  background-color: #000000;
  animation: fv-text 0.4s ease-out;
  animation-delay: 0.8s;
  animation-fill-mode: both;
}
@media screen and (min-width: 768px) {
  .p-fv__textBorder::before {
    background-color: transparent;
  }
}

@keyframes fv-text {
  0% {
    bottom: 160px;
  }
  40% {
    bottom: 100px;
  }
  90% {
    bottom: 10px;
  }
  100% {
    bottom: 0px;
  }
}
.p-header {
  position: relative;
  background-color: #EFEDE1;
}
@media screen and (min-width: 768px) {
  .p-header {
    overflow: hidden;
  }
}

.p-header__borderAnime {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #EFEDE1;
  z-index: 9999999;
}
@media screen and (min-width: 768px) {
  .p-header__borderAnime {
    position: static;
    overflow: hidden;
  }
}

.p-header__borderAnime::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: 1px;
  background-color: #000000;
  z-index: 999999;
  animation: "border-anime2" 2s linear;
}
@media screen and (min-width: 768px) {
  .p-header__borderAnime::before {
    left: 8%;
  }
}

@keyframes border-anime2 {
  0% {
    width: 0vw;
  }
  100% {
    width: 100vw;
  }
}
.p-header__wrap {
  width: 92%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1.25rem;
  padding-top: 1.875rem;
  padding-bottom: 0.90625rem;
  padding-left: 0.5rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-header__logo img {
    max-width: 31.25rem;
    width: 41.6666666667vw;
  }
}

.p-drawer__logo img {
  max-width: 17.5rem;
}
@media screen and (min-width: 768px) {
  .p-drawer__logo img {
    max-width: 31.25rem;
    width: 41.6666666667vw;
  }
}

.p-drawer__wrap {
  width: 92%;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .p-drawer__wrap {
    padding-right: 2.8125rem;
  }
}

.p-header__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-header__link {
    flex-direction: row;
    position: absolute !important;
    bottom: 10%;
    left: -8.3333333333vw;
  }
}

.p-header__link .p-header__snsText,
.p-header__link .p-header__mailText {
  color: #F4F2E6;
}

.p-header__contact, .p-header__sns, .p-header__mail {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media screen and (min-width: 768px) {
  .p-header__contact, .p-header__sns, .p-header__mail {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
}

.p-header__contact {
  margin-bottom: 2.1875rem;
}
@media screen and (min-width: 768px) {
  .p-header__contact {
    margin-bottom: 0;
    width: 21.0526315789vw;
  }
}
@media screen and (min-width: 1351px) and (max-width: 1850px) {
  .p-header__contact {
    width: 26.3157894737vw;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1350px) {
  .p-header__contact {
    width: 31.5789473684vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .p-header__contact {
    width: 36.8421052632vw;
  }
}

.p-header__sns {
  width: 50%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border-right: 1px solid #707070;
}
@media screen and (min-width: 768px) {
  .p-header__sns {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-right: 1px solid #707070;
  }
}

.p-header__sns img {
  width: 1.25rem;
}

.p-header__mail {
  width: 50%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-header__mail {
    padding-left: 2.1052631579vw;
  }
}

.p-header__mail img {
  width: 2.5rem;
}

.p-header__snsText, .p-header__mailText {
  color: #3C6866;
  font-size: 0.9375rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .p-header__snsText, .p-header__mailText {
    padding-left: 0.7894736842vw;
    padding-right: 0.7894736842vw;
  }
}

.header__nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  transform: translateY(-100vh);
  background-color: #296966;
  background-size: cover;
  transition: ease 0.4s;
  z-index: 99999;
}
@media screen and (min-width: 768px) {
  .header__nav {
    width: 100vw;
    height: 100vh;
  }
}

.nav__items {
  position: absolute;
  top: 55%;
  left: 43%;
  transform: translate(-50%, -50%);
  padding: 0;
}
@media screen and (min-width: 768px) {
  .nav__items {
    left: 50%;
    display: flex;
  }
}

.nav__cta {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  background-color: #fff;
  border: 3px solid #E9D427;
  border-radius: 1.25rem;
  width: 80%;
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
  text-align: center;
  font-size: 1.3125rem;
}
@media screen and (min-width: 768px) {
  .nav__cta {
    font-size: 0.9473684211vw;
  }
}

.nav-items a {
  color: #F4F2E6;
  width: 15.625rem;
  display: block;
  font-size: 2.5rem;
  margin-bottom: 1.25rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  transform: scale(0.7, 1);
}
@media screen and (min-width: 768px) {
  .nav-items a {
    font-size: 2.8571428571vw;
    margin-right: 0.625rem;
    width: 16.875rem;
  }
}

.nav-items:last-child a {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .nav-items__item {
    width: 12.5vw;
  }
}

.header__hamburger {
  width: 2.8125rem;
  height: 100%;
  z-index: 9999999;
}
@media screen and (min-width: 768px) {
  .header__hamburger {
    position: fixed;
    transform: translateY(-20px);
  }
}

.hamburger {
  background-color: transparent;
  border-color: transparent;
}
@media screen and (min-width: 768px) {
  .hamburger {
    margin-left: 3.125vw;
  }
}
@media screen and (max-width: 1500px) {
  .hamburger {
    margin-left: 2.6666666667vw;
  }
}
@media screen and (max-width: 1100px) {
  .hamburger {
    margin-left: 2.2727272727vw;
  }
}

.hamburger span {
  width: 100%;
  height: 4px;
  background-color: #707070;
  border-radius: 3.125rem;
  position: relative;
  transition: ease 0.4s;
  display: block;
}

.hamburger span:nth-child(1) {
  top: 0;
}

.hamburger span:nth-child(2) {
  margin: 8px 0;
}

.hamburger span:nth-child(3) {
  top: 0;
}

.header__nav.active {
  opacity: 1;
  transform: translateX(0);
  transition: 0.4s;
  padding-top: 12.5vw;
}
@media screen and (min-width: 768px) {
  .header__nav.active {
    padding-top: 3.125rem;
    transition: 0.5s;
    width: 100vw;
    height: 100vh;
    transform: translateY(0);
  }
}

.header__nav.active .p-drawer__logo {
  margin-bottom: 1.25rem;
}

.hamburger.active span:nth-child(1) {
  top: 10px;
  transform: rotate(45deg);
  background-color: #F4F2E6;
}

.hamburger.active span:nth-child(2) {
  opacity: 0;
}

.hamburger.active span:nth-child(3) {
  top: -13px;
  transform: rotate(-45deg);
  background-color: #F4F2E6;
}

@media screen and (min-width: 768px) {
  .p-drawer__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.p-intro {
  padding-bottom: 4.375rem;
  border-bottom: 1px solid #0E1215;
}
@media screen and (min-width: 768px) {
  .p-intro {
    border-bottom: none;
    padding-bottom: 3.6842105263vw;
    padding-top: 4.6315789474vw;
  }
}

.p-intro__flex {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .p-intro__flex {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
    padding-left: 2.6315789474vw;
    padding-right: 2.6315789474vw;
  }
}

@media screen and (min-width: 768px) {
  .p-intro__flexLeft {
    width: 45%;
  }
}

@media screen and (min-width: 768px) {
  .p-intro__flexRight {
    width: 55%;
    padding-top: 4.2105263158vw;
    padding-left: 1.5625rem;
    padding-bottom: 2.6315789474vw;
  }
}

.p-intro__icon {
  width: 3.75rem;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .p-intro__icon {
    width: 5.2631578947vw;
  }
}

.p-intro__img {
  width: 90%;
  margin: 0 auto;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
@media screen and (min-width: 768px) {
  .p-intro__slide {
    width: 70%;
    margin: 0 auto;
  }
}

.p-intro__title {
  font-size: 1.875rem;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  transform: scale(0.8, 1);
}
@media screen and (min-width: 768px) {
  .p-intro__title {
    font-size: 2.1052631579vw;
  }
}

.p-intro__name {
  font-size: 3.125rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  transform: scale(0.8, 1);
  margin-bottom: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-intro__name {
    font-size: 3.6842105263vw;
  }
}

@media screen and (min-width: 768px) {
  .p-intro__explaWrap {
    margin-bottom: 3.1578947368vw;
  }
}

.p-intro__explanation {
  font-size: 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-intro__explanation {
    text-align: left;
    font-size: 1.3157894737vw;
  }
}

.p-intro__large {
  font-size: 0.9375rem;
  font-weight: 700;
}
@media screen and (min-width: 768px) {
  .p-intro__large {
    font-size: 1.5789473684vw;
  }
}

.p-intro__description {
  font-size: 0.5625rem;
  line-height: 2;
  margin-bottom: 2.3125rem;
}
@media screen and (min-width: 768px) {
  .p-intro__description {
    font-size: 0.7894736842vw;
    text-align: left;
    margin-bottom: 1.9473684211vw;
  }
}

.p-intro__english {
  font-size: 0.5rem;
  line-height: 2;
}
@media screen and (min-width: 768px) {
  .p-intro__english {
    font-size: 0.7894736842vw;
    text-align: left;
  }
}

.wrapper {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.typing-demo {
  width: 38ch;
  animation: typing 2s steps(30), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid transparent;
  letter-spacing: 2px;
  margin: 0 auto;
}

@keyframes typing {
  from {
    width: 0;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}
.p-media {
  padding-top: 1.875rem;
  padding-bottom: 4.375rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .p-media {
    border-bottom: none;
    padding-top: 1.5789473684vw;
    padding-bottom: 3.6842105263vw;
  }
}

@media screen and (min-width: 768px) {
  .p-media .l-inner {
    padding-left: 5.7894736842vw;
    padding-right: 5.7894736842vw;
  }
}

@media screen and (min-width: 768px) {
  .p-media__flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.p-media .c-title {
  font-size: 3.125rem;
  margin-bottom: 1.25rem;
  padding-top: 5rem;
  margin-top: -5rem;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  transform: scale(0.8, 1);
}
@media screen and (min-width: 768px) {
  .p-media .c-title {
    font-size: 3.6842105263vw;
    width: 0%;
  }
}

@media screen and (min-width: 768px) {
  .p-media__box {
    font-size: 1.3157894737vw;
    padding-top: 2.2631578947vw;
    padding-bottom: 2.2631578947vw;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }
}

@media screen and (min-width: 768px) {
  .p-media__flexLeft {
    width: 55%;
    text-align: left;
    padding-left: 5rem;
    padding-right: 5rem;
    transform: translateY(-40px);
  }
}

@media screen and (min-width: 768px) {
  .p-media__flexRight {
    width: 45%;
  }
}

@media screen and (min-width: 768px) {
  .p-media__flexRight img {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .p-work__slideItems2 {
    position: relative;
  }
}

.prev-arrow-media {
  display: none !important;
}
@media screen and (min-width: 768px) {
  .prev-arrow-media {
    display: block !important;
    position: absolute;
    bottom: -5.625rem;
    right: 15%;
    width: 2.8947368421vw !important;
  }
}

.next-arrow-media {
  display: none !important;
}
@media screen and (min-width: 768px) {
  .next-arrow-media {
    display: block !important;
    position: absolute;
    bottom: -5.625rem;
    right: 0%;
    width: 2.8947368421vw !important;
  }
}

.p-media__flexRight .next-arrow-media,
.p-media__flexRight .prev-arrow-media {
  display: none !important;
}

.p-plan {
  padding-left: 1.875rem;
  padding-right: 5rem;
  padding-bottom: 1.875rem;
  background-color: #EFEFEF;
}
@media screen and (min-width: 768px) {
  .p-plan {
    padding-right: 13.6842105263vw;
    padding-left: 13.6842105263vw;
    padding-top: 7.8947368421vw;
  }
}

@media screen and (min-width: 768px) {
  .p-plan__planFlex {
    display: flex;
    align-items: center;
  }
}

.p-plan__titleBox {
  position: relative;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 1.25rem;
  transform: translateY(-30px);
}

@media screen and (min-width: 768px) {
  .p-plan__titleBox img {
    position: absolute;
    top: -13.1578947368vw;
    right: -8.9473684211vw;
    max-width: 36.5625rem;
    width: 30.7894736842vw;
  }
}

@media screen and (min-width: 768px) {
  .p-plan__mainTitle {
    position: absolute;
    top: -14.2105263158vw;
  }
}

.p-plan__title {
  position: absolute;
  top: 2.5641025641vw;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-weight: 400;
  font-size: 5.641025641vw;
}
@media screen and (min-width: 768px) {
  .p-plan__title {
    top: -12.1052631579vw;
    transform: initial;
    left: initial;
    right: 2.6315789474vw;
    font-size: 2.6315789474vw;
  }
}

.p-plan__text {
  background-color: #fff;
  color: #00565F;
  display: inline;
  position: absolute;
  bottom: 25%;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  text-align: center;
  font-size: 5.641025641vw;
}
@media screen and (min-width: 768px) {
  .p-plan__text {
    font-size: 1.5789473684vw;
    width: 40%;
    right: -10%;
    left: initial;
    transform: initial;
    transform: translateY(-3.6842105263vw);
  }
}

.p-plan__planBox {
  background-color: #fff;
  border-top: 12px solid #EAD527;
  color: #00565F;
  text-align: center;
  font-weight: 400;
  padding-top: 1.5625rem;
  padding-bottom: 2.1875rem;
  margin-bottom: 1.875rem;
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .p-plan__planBox {
    width: 33%;
    margin-right: 3.125rem;
  }
}

.p-plan__planBox:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.p-plan__planBox:last-child {
  margin-right: 0;
}

.p-plan__planBox:nth-child(1) {
  border-top: 12px solid #EAD527;
}

.p-plan__planBox:nth-child(2) {
  border-top: 12px solid #EDAB44;
}

.p-plan__planBox:nth-child(3) {
  border-top: 12px solid #128DA4;
}

.p-plan__boxTitle {
  font-size: 1.25rem;
  font-weight: 900;
  margin-bottom: 0.3125rem;
}
@media screen and (min-width: 768px) {
  .p-plan__boxTitle {
    font-size: 1.5789473684vw;
  }
}

.p-plan__subtitle {
  font-size: 0.8125rem;
  margin-bottom: 1.25rem;
  font-weight: 900;
}
@media screen and (min-width: 768px) {
  .p-plan__subtitle {
    font-size: 1.0526315789vw;
  }
}

.p-plan__price {
  font-size: 1rem;
  margin-bottom: 0.3125rem;
  font-weight: 900;
}
@media screen and (min-width: 768px) {
  .p-plan__price {
    font-size: 1.3157894737vw;
  }
}

.p-plan__planBox img {
  display: inline;
}

.p-plan__img1 {
  width: 39%;
}

.p-plan__img2 {
  width: 52%;
}

.p-plan__img3 {
  width: 40%;
}

.p-plan__services {
  max-width: 150px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  padding-left: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-plan__services {
    max-width: 12.5rem;
  }
}

@media screen and (min-width: 768px) {
  .p-plan__service {
    font-size: 1.0526315789vw;
  }
}

.service_mark {
  position: relative;
}

.p-plan__service::before {
  position: absolute;
  content: url(../images/plan_item_logo.svg);
  top: 0.1875rem;
  left: -1.5625rem;
  width: 0.9375rem;
}

.p-plan__planBox:nth-child(2) .service_mark::before {
  content: url(../images/plan_item_logo_orange.svg);
}

.p-plan__planBox:nth-child(3) .service_mark::before {
  content: url(../images/plan_item_logo_buz.svg);
}

.p-plan__text {
  font-size: 5.1282051282vw;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .p-plan__text {
    font-size: 2.6315789474vw;
  }
}

@media screen and (max-width: 390px) {
  .p-plan__text {
    font-size: 3.8461538462vw;
  }
}
.p-plan__text--s {
  font-size: 3.3333333333vw;
}
@media screen and (min-width: 768px) {
  .p-plan__text--s {
    font-size: 1.5789473684vw;
  }
}

.plus::before {
  content: "";
}

@media screen and (min-width: 768px) {
  .plus {
    padding-left: 3.125rem;
  }
}

.p-plan__mb {
  margin-bottom: 1.0526315789vw;
}

#profile {
  padding-top: 5rem;
  margin-top: -5rem;
}

.p-profile {
  padding-bottom: 10.625rem;
  text-align: center;
  border-top: 1px solid #0e1215;
  border-bottom: 1px solid #0E1215;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .p-profile {
    padding-top: 5.7894736842vw;
    padding-bottom: 5.7894736842vw;
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
    border-bottom: none;
  }
}

@media screen and (min-width: 768px) {
  .p-profile::before {
    content: "";
    position: absolute;
    top: -0.625rem;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: #ffffff;
  }
}

@media screen and (min-width: 768px) {
  .p-profile .l-inner {
    padding-left: 5.7894736842vw;
    padding-right: 5.7894736842vw;
  }
}

.p-profile__title {
  font-size: 1.875rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  transform: scale(0.8, 1);
  margin-top: -0.625rem;
}
@media screen and (min-width: 768px) {
  .p-profile__title {
    font-size: 2.6315789474vw;
    text-align: left;
    transform: translateX(2.5vw);
  }
}

.p-profile .c-title {
  border-bottom: 1px solid #000000;
  margin-bottom: 1.875rem;
  padding-top: 5rem;
  margin-top: -5rem;
}
@media screen and (min-width: 768px) {
  .p-profile .c-title {
    border-bottom: none;
  }
}

.p-profile .c-title h2 {
  font-size: 3.125rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  transform: scale(0.7, 1);
}
@media screen and (min-width: 768px) {
  .p-profile .c-title h2 {
    font-size: 2.6315789474vw;
  }
}

.p-profile__img {
  width: 6.25rem;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .p-profile__img {
    margin-left: 0;
    margin-right: 0;
    width: 5.2631578947vw;
  }
}

.p-profile__name {
  font-size: 6.25rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  transform: scale(0.8, 1);
}
@media screen and (min-width: 768px) {
  .p-profile__name {
    font-size: 5.2631578947vw;
  }
}

.p-profile__profile {
  text-align: left;
  line-height: 2;
  font-size: 0.5625rem;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 5.6875rem;
}
@media screen and (min-width: 768px) {
  .p-profile__profile {
    font-size: 1.25rem;
    font-size: 1.0526315789vw;
    margin-bottom: 0;
  }
}

.p-profile__iconBox {
  position: relative;
  margin-bottom: 3.75rem;
}
@media screen and (min-width: 768px) {
  .p-profile__iconBox {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-left: 2.6315789474vw;
    padding-right: 4.375rem;
  }
}

@media screen and (min-width: 768px) {
  .p-profile__iconBoxLeft {
    display: flex;
    align-items: center;
  }
}

.p-profile__photo {
  width: 85%;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .p-profile__flex {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .p-profile__flexLeft {
    width: 55%;
    padding-left: 3.125rem;
  }
}

.p-profile__flexRight {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-profile__flexRight {
    perspective: 500px;
    width: 45%;
  }
}

.p-profile__gearPosition {
  position: absolute;
  top: 48%;
  right: -120px;
  width: 12.5rem;
}
@media screen and (min-width: 768px) {
  .p-profile__gearPosition {
    width: 50%;
    position: absolute;
    top: 30%;
    right: -9.375rem;
  }
}

.p-profile__gears img {
  animation: rotation 4s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p-profile__slide {
  position: relative;
  transform: rotateY(-10deg);
}

.p-profile__slidebg {
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
@media screen and (min-width: 768px) {
  .p-profile__slidebg {
    width: 80%;
  }
}

.p-profile__slideItems {
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
}
@media screen and (min-width: 768px) {
  .p-profile__slideItems {
    top: 2.8947368421vw;
    width: 65%;
  }
}

.p-profile__snsWrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media screen and (min-width: 768px) {
  .p-profile__snsWrap {
    position: initial;
    transform: translateY(-15px);
  }
}

.p-profile__snsWrap img {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
  width: 2.0625rem;
}
@media screen and (min-width: 768px) {
  .p-profile__snsWrap img {
    width: 3.625rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    width: 3.0526315789vw;
  }
}

.next-arrow-profile {
  position: absolute;
  width: 35vw;
  top: -37.5vw;
  right: -32.5vw;
  transition: 1s;
  transform: rotate(0deg);
}
@media screen and (min-width: 768px) {
  .next-arrow-profile {
    position: absolute;
    width: 15.625rem;
    top: 0;
    right: -16.875rem;
    transition: 1s;
    transform: rotate(0deg);
  }
}

.next-arrow-profile.is-active {
  transition: 1s;
  transform: rotate(180deg);
}
@media screen and (min-width: 768px) {
  .next-arrow-profile.is-active {
    transition: 1s;
    transform: rotate(180deg);
  }
}

.profile-line {
  margin-bottom: 5px;
  width: 100%;
  height: 6px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.profile-line12 {
  margin-bottom: 5px;
  width: 100%;
  height: 12px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.profile-lineBottom {
  margin-top: 5px;
  width: 100%;
  height: 6px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.p-service {
  padding-top: 2.6875rem;
  padding-bottom: 4.6875rem;
  border-bottom: 1px solid #0E1215;
}
@media screen and (min-width: 768px) {
  .p-service {
    border-bottom: none;
    padding-top: 2.2631578947vw;
    padding-bottom: 3.9473684211vw;
  }
}

.p-service__title {
  font-size: 0.9375rem;
  line-height: 2;
  color: #191817;
  margin-bottom: 2.75rem;
  font-weight: 300;
}
@media screen and (min-width: 768px) {
  .p-service__title {
    font-size: 1.3157894737vw;
    text-align: left;
  }
}

.p-service__img {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-service__img {
    display: block;
    width: 18.4210526316vw;
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }
}

.p-service__text {
  font-size: 0.5rem;
}
@media screen and (min-width: 768px) {
  .p-service__text {
    font-size: 0.7894736842vw;
    text-align: left;
    margin-bottom: 1.875rem;
  }
}

.p-service__view {
  font-size: 1.5625rem;
  text-align: right;
  padding-right: 3.125rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-service__view {
    font-size: 1.3157894737vw;
  }
}

.p-service__view::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10%;
  width: 45%;
  height: 2px;
  background-color: #707070;
  animation: "service-border" 2s linear;
}

@keyframes service-border {
  0% {
    width: 0%;
  }
  100% {
    width: 45%;
  }
}
.p-service__slide {
  width: 90%;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .p-service__slide {
    width: 70%;
  }
}

@media screen and (min-width: 768px) {
  .p-service__flex {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .p-service__flexLeft {
    width: 45%;
    position: relative;
  }
}

@media screen and (min-width: 768px) {
  .p-service__flexLeft::before {
    content: "";
    position: absolute;
    top: 0%;
    right: 2.1052631579vw;
    width: 1px;
    height: 80%;
    background-color: #707070;
    animation: "service-border2" 2s linear;
  }
}

@keyframes service-border2 {
  0% {
    height: 0%;
  }
  100% {
    height: 50%;
  }
}
@media screen and (min-width: 768px) {
  .p-service__flexRight {
    width: 55%;
    padding-left: 1.5625rem;
  }
}

.p-service__thumbnail {
  display: none !important;
}
@media screen and (min-width: 768px) {
  .p-service__thumbnail {
    display: block !important;
    width: 80%;
    margin: 0 auto;
  }
}

.typing-demo2 {
  width: 33ch;
  animation: typing 3s steps(30);
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 2px;
  margin: 0 auto;
  margin-right: 4.7368421053vw;
}

@keyframes typing {
  from {
    width: 0;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}
.dots-class button {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  padding: 0;
  border: none;
  background-color: transparent;
}

.dots-class {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.dots-class li {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 1px solid #000066;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.dots-class li.slick-active {
  background-color: #000066;
}

.dots-class li:hover {
  background-color: #000066;
}

.dots-class li:last-child {
  margin-right: 0;
}

.slick-arrow:before {
  content: "" !important;
}

.p-service__ctaBtn {
  display: inline-block;
  border: 5px solid #EBD400;
  border-radius: 1.25rem;
  width: 60%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  position: relative;
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .p-service__ctaBtn {
    font-size: 1.4210526316vw;
  }
}

.p-service__ctaBtn:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.p-service__ctaBtn::before {
  content: "詳しくは";
  position: absolute;
  top: -0.625rem;
  left: 0rem;
  color: #005861;
  font-size: 0.5rem;
}
@media screen and (min-width: 768px) {
  .p-service__ctaBtn::before {
    top: -1.25rem;
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 700px) and (max-width: 768px) {
  .p-service__slideItem img {
    width: 105%;
  }
}
.p-service__slideItem {
  position: relative;
}

.p-service__slideItem .p-service__movie {
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
}

.p-service__slideItem .p-service__video {
  position: absolute;
  width: 50%;
  right: 20%;
}

.p-service__movie {
  width: 100%;
  position: relative;
}

.p-service__video {
  position: absolute;
  bottom: 10%;
  right: 10%;
  width: 27%;
}

.p-service__video2 {
  position: absolute;
  bottom: 20%;
  right: 10%;
  width: 30%;
}

.p-service__left {
  position: absolute;
  top: 10%;
  left: 5%;
  color: #00565F;
  width: 90%;
}
@media screen and (min-width: 768px) {
  .p-service__left {
    width: 40%;
  }
}

.p-service__header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.0526315789vw;
}

.p-service__num {
  color: #128CA3;
  font-weight: 700;
  margin-right: 2.6315789474vw;
  font-size: 9vw;
}
@media screen and (min-width: 768px) {
  .p-service__num {
    font-size: 4.2105263158vw;
  }
}

.p-service__middle {
  margin-bottom: 1.0526315789vw;
}

.p-service__serviceVideo01 {
  margin: 0 auto;
  width: 70%;
}
@media screen and (min-width: 768px) {
  .p-service__serviceVideo01 {
    width: 90%;
  }
}

.p-service__serviceVideo02 {
  margin: 0 auto;
  width: 55%;
}
@media screen and (min-width: 768px) {
  .p-service__serviceVideo02 {
    width: 70%;
  }
}

.p-service__serviceVideo03 {
  margin: 0 auto;
  width: 40%;
}
@media screen and (min-width: 768px) {
  .p-service__serviceVideo03 {
    width: 50%;
  }
}

.p-service__leftTitle {
  text-align: center;
  font-size: 3.5vw;
}
@media screen and (min-width: 768px) {
  .p-service__leftTitle {
    font-size: 1.5789473684vw;
  }
}

.p-service__footer {
  font-size: 1.0526315789vw;
  text-align: center;
}

.p-service .prev-arrow {
  position: absolute;
  top: -1.875rem;
  left: 30%;
  z-index: 999;
  width: 1.875rem;
}

.p-service .next-arrow {
  position: absolute;
  top: -1.875rem;
  right: 30%;
  width: 1.875rem;
}

.p-service_anime04 {
  width: 70%;
  margin: 0 auto;
}

a.anchor {
  display: block;
  transform: translateY(50px);
}

a.anchor2 {
  display: block;
  transform: translateY(-50px);
}

a.anchor3 {
  display: block;
  transform: translateY(60px);
}

.transformed {
  background-color: red;
  perspective: 300px;
  width: 300px;
  height: 300px;
  transform-style: flat;
  transform: rotateY(50deg);
}

.p-step {
  padding-top: 5rem;
  padding-right: 3.125rem;
  padding-bottom: 5rem;
  text-align: center;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-step {
    padding-top: 9.375rem;
    padding-right: 0;
    padding-bottom: 18.75rem;
  }
}

.p-step__toplogo {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
}

.p-step__bottomlogo {
  position: absolute;
  bottom: 1.25rem;
  right: 3.125rem;
  width: 60%;
}
@media screen and (min-width: 768px) {
  .p-step__bottomlogo {
    right: 0;
  }
}

.p-step__drop1 {
  position: absolute;
  bottom: 0.5263157895vw;
  left: 20%;
  max-width: 3.75rem;
  width: 3.1578947368vw;
}
@media screen and (min-width: 768px) {
  .p-step__drop1 {
    left: 35%;
  }
}

.p-step__drop2 {
  position: absolute;
  bottom: -7.8947368421vw;
  left: 18.7%;
  max-width: 6.25rem;
  width: 5.2631578947vw;
}
@media screen and (min-width: 768px) {
  .p-step__drop2 {
    left: 33.7%;
  }
}

.p-step__logo {
  display: inline;
}
@media screen and (min-width: 768px) {
  .p-step__logo {
    width: 26.3157894737vw;
  }
}

.typing {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-step__description {
  width: 18ch;
  font-size: 1rem;
  color: #00565F;
  margin-bottom: 1.25rem;
  animation: typing 3s steps(18), effect 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .p-step__description {
    font-size: 1.875rem;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
}
@keyframes effect {
  50% {
    border-color: transparent;
  }
}
@media screen and (min-width: 768px) {
  .p-step__slideFlex {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-around;
  }
}

.p-step__slideItem img {
  max-width: 16.875rem;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 5rem;
}
@media screen and (min-width: 768px) {
  .p-step__slideItem img {
    margin: 0;
    margin-left: auto;
    padding-right: 9.375rem;
    margin-top: 1.25rem;
  }
}

.p-step__slideText {
  color: #00565F;
}
@media screen and (min-width: 768px) {
  .p-step__slideText {
    width: 40%;
    z-index: 20;
    margin-right: -13.1578947368vw;
  }
}

.p-step__slideTitle {
  font-size: 1rem;
  margin-bottom: 1.25rem;
  font-weight: 700;
}
@media screen and (min-width: 768px) {
  .p-step__slideTitle {
    font-size: 1.5789473684vw;
  }
}

.p-step__slideBody {
  font-size: 0.8125rem;
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-step__slideBody {
    font-size: 1.0526315789vw;
  }
}

@media screen and (min-width: 768px) {
  .slideBody01 {
    margin-bottom: 0;
  }
}

.p-step__ctaBtn {
  display: inline-block;
  background-color: #E9D427;
  border-radius: 0.9375rem;
  max-width: 12.5rem;
  width: 90%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .p-step__ctaBtn {
    margin: 0 auto;
    font-size: 0.9473684211vw;
    margin-top: 2.1875rem;
  }
}

.p-step__ctaBtn:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.p-step__lineheight {
  line-height: 2.3;
}

.p-step__movie {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-step__movie {
    width: 50%;
    margin: 0;
    margin-left: -13.1578947368vw;
    z-index: 10;
  }
}

.p-step__movie video {
  width: 85%;
}
@media screen and (min-width: 768px) {
  .p-step__movie video {
    width: 100%;
  }
}

.p-step__mtadj {
  margin-top: -0.625rem;
}

.p-step__mtadj3 {
  margin-top: 2.5rem;
}

.p-step__wizard {
  display: none !important;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .p-step__wizard {
    display: block !important;
    max-width: 64.2105263158vw !important;
    text-align: right;
    margin-right: 2.6315789474vw;
    z-index: 99999;
  }
}

.p-step .prev-arrow {
  position: absolute;
  top: 42%;
  left: 1.875rem;
  z-index: 999;
  width: 7.5vw;
}
@media screen and (min-width: 768px) {
  .p-step .prev-arrow {
    top: 74%;
    left: 12%;
    left: 14.7368421053vw;
    width: 3.1578947368vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
  .p-step .prev-arrow {
    top: 76%;
  }
}
@media screen and (min-width: 1600px) {
  .p-step .prev-arrow {
    top: 72%;
  }
}

.p-step .next-arrow {
  position: absolute;
  top: 42%;
  right: 1.875rem;
  width: 7.5vw;
}
@media screen and (min-width: 768px) {
  .p-step .next-arrow {
    top: 74%;
    left: 38%;
    left: 39.4736842105vw;
    width: 3.1578947368vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
  .p-step .next-arrow {
    top: 76%;
  }
}
@media screen and (min-width: 1600px) {
  .p-step .next-arrow {
    top: 72%;
  }
}

.p-test {
  color: red;
  font-size: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .p-test {
    font-size: 3.125rem;
  }
}

.p-work {
  padding-top: 1.875rem;
  padding-bottom: 15.625rem;
  text-align: center;
  border-bottom: 1px solid #0E1215;
}
@media screen and (min-width: 768px) {
  .p-work {
    position: relative;
    border-bottom: none;
    padding-top: 1.5789473684vw;
    padding-bottom: 13.1578947368vw;
  }
}

.p-work__text {
  margin-top: 1.25rem;
  font-size: 0.75rem;
  line-height: 1;
  margin-bottom: 3.125rem;
}

.p-work__img {
  width: 60%;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .p-work__img {
    width: 60%;
  }
}

.p-work__box {
  font-size: 0.75rem;
  line-height: 2;
  position: relative;
}

@media screen and (min-width: 768px) {
  .p-work__flex {
    display: flex;
    align-items: center;
    position: absolute;
    top: -6.25rem;
    left: 0;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .p-work__flex {
    left: 50%;
    transform: translateX(-50%);
    width: 88%;
  }
}

@media screen and (min-width: 768px) {
  .p-work__flexLeft {
    width: 50%;
    padding-top: 18.75rem;
    text-align: left;
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media screen and (min-width: 768px) {
  .p-work__flexRight {
    width: 50%;
    position: relative;
  }
}

.p-work__background {
  transform: translateY(50px);
}
@media screen and (min-width: 768px) {
  .p-work__background {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.p-work__backItems {
  width: 60%;
  margin: 0 auto;
}
.p-work__backItems video {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .p-work__backItems video {
    width: 100%;
  }
}

.p-work__background .slick-next {
  display: none !important;
}

.p-work__slide {
  position: absolute;
  top: -5%;
  left: 0;
  width: 100%;
  z-index: 99;
}
@media screen and (min-width: 768px) {
  .p-work__slide {
    top: 50%;
  }
}

.slick-list {
  height: 50%;
}

.p-work__display {
  display: none !important;
}
@media screen and (min-width: 768px) {
  .p-work__display {
    display: block !important;
  }
}

.p-work .c-title {
  font-size: 3.125rem;
  margin-bottom: 1.5625rem;
  padding-top: 5rem;
  margin-top: -5rem;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  transform: scale(0.8, 1);
}
@media screen and (min-width: 768px) {
  .p-work .c-title {
    font-size: 3.6842105263vw;
    width: 0%;
  }
}

@media screen and (min-width: 768px) {
  .p-work__text {
    font-size: 1.3157894737vw;
  }
}

@media screen and (min-width: 768px) {
  .p-work__slideItems {
    position: relative;
  }
}

.prev-arrow {
  display: none !important;
}
@media screen and (min-width: 768px) {
  .prev-arrow {
    display: block !important;
    position: absolute;
    bottom: -4.375rem;
    left: 40%;
    width: 2.8947368421vw;
  }
}

.next-arrow {
  display: none !important;
}
@media screen and (min-width: 768px) {
  .next-arrow {
    display: block !important;
    position: absolute;
    bottom: -4.375rem;
    right: 40%;
    width: 2.8947368421vw;
  }
}

.p-workForBack .prev-arrow,
.p-workForBack .next-arrow {
  display: none !important;
}

.u-bold {
  font-weight: 900;
}

.u-bold7 {
  font-weight: 700;
}

.u-desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-desktop {
    display: block;
  }
}

.u-inline {
  display: inline;
}

@media screen and (min-width: 768px) {
  .u-mobile {
    display: none !important;
  }
}

.u-ub10 {
  margin-bottom: 0.625rem;
}

.u-ub20 {
  margin-bottom: 1.25rem;
}