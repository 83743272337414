@use "global" as *;

.p-bodyWrap{
    // max-width: rem(360);
    // width: calc(372/400*100vw);
    width: 100%;
    padding-left: calc(30/400*100vw);
    margin-left: auto;
    text-align: center;
    position: relative;
    @include mq(md){
        width: 92%;
        padding-left: 0;
    }
    // border-left: 1px solid #0E1215;
}
body{
    position: relative;
}
body::before{
    content: "";
    position: absolute;
    top: 0;
    left: calc(30/400*100vw);
    width: 1px;
    height: 100%;
    background-color: #000000;
    z-index: 999999;
    animation: "border-anime" 10s linear;
    @include mq(md){
        left: calc(100/1260*100vw);
    }
}

@keyframes border-anime {
	0%{
		height: 0%;
	}
	100%{
		height: 100%;
	}
}
